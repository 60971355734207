import React from "react";
import { useEffect } from "react";
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetectorCore, AwsCredentialProvider } from '@aws-amplify/ui-react-liveness';
import { defaultLivenessDisplayText } from '../Data/displayText.ts'
import Swal from 'sweetalert2'

function FaceLiveness({faceLivenessAnalysis}) {
    const [loading, setLoading] = React.useState(false);
    const [sessionId, setSessionId] = React.useState(null)
    const [tenantId, setTentantId] = React.useState(null)
    const [awsCredentials, setAwsCredentials] = React.useState(null)
    const [minConfidence, setMinConfidence] = React.useState(null)
   
    const endpoint = process.env.REACT_APP_ENV_API_URL ? process.env.REACT_APP_ENV_API_URL : ''

    useEffect(() => {
        init();
    },[])

    const init = async() => {
      if(!loading) {
        createSession()
      }
    }

    /*
     * API call to create the Face Liveness Session
     */
    const createSession = async () => {
        setLoading(true);

        const queryString = window.location.href;
        const parameters = new URLSearchParams(queryString);
        const token = parameters.get('token')
        const tenant_id = parameters.get('tenant_id')
        setTentantId(tenant_id)

        if(!token || !tenant_id) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Mancano parametri obbligatori',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false
          })
          return
        }

        try {
          const response = await fetch(endpoint + 'liveness-session',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  token: token,
                  tenant_id: tenant_id
                })
            }
          );

          if(!response.ok) {
            console.log("ERR",response)
            Swal.fire(
              'Test fallito!',
              'Si è verificato un errore. Riprova',
              'error'
            )
            setLoading(false);
            return
          }

          const body = await response.json();
          setSessionId(body.data.session_id)
          setMinConfidence(body.data.min_confidence)
          setAwsCredentials({
            accessKeyId: body.data.aws_credentials.AccessKeyId,
            secretAccessKey: body.data.aws_credentials.SecretAccessKey,
            sessionToken: body.data.aws_credentials.SessionToken
          })

          setLoading(false);

        } catch(err) {
          console.log("ERR",err)
          setLoading(false);
        }
    };

    const credentialProvider: AwsCredentialProvider = async () => {
      return awsCredentials
    }

    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async () => {
      try {
        const response = await fetch(endpoint + 'liveness-session/complete',
          {
              method: 'PATCH',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                session_id: sessionId,
                tenant_id: tenantId
              })
          }
        );

        if(!response.ok) {
          console.log("ERR",response)
          Swal.fire(
            'Test fallito!',
            'Si è verificato un errore. Riprova',
            'error'
          ).then((result) => {
            createSession()
          })
          return
        }

        const body = await response.json();
        const confidence = Math.round(parseFloat(body.data.Confidence))

        if(confidence >= minConfidence) {
          Swal.fire({
            title: '<strong>Complimenti</strong>',
            icon: 'success',
            html: 'La procedura è stata completata correttamente.<br/>Clicca qui per tornare su Whatsapp',
            focusConfirm: true,
            confirmButtonText: 'Procedi',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            window.location.href = body.data.url
          })
        } else {
          Swal.fire(
            'Test fallito!',
            'Non sei una persona... riprova',
            'error'
          ).then((result) => {
            createSession()
          })
        }

      } catch(err) {
        console.log("ERR",err)
        Swal.fire(
          'Test fallito!',
          'Si è verificato un errore. Riprova',
          'error'
        ).then((result) => {
          createSession()
        })
      }
      
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetectorCore
                    sessionId={sessionId}
                    region="eu-west-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    displayText={defaultLivenessDisplayText}
                    config={{ credentialProvider }}
                    onError={(error) => {
                      console.log(error);
                      createSession()
                    }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
