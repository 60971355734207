export type HintDisplayText = {
  hintMoveFaceFrontOfCameraText?: string;
  hintTooManyFacesText?: string;
  hintFaceDetectedText?: string;
  hintCanNotIdentifyText?: string;
  hintTooCloseText?: string;
  hintTooFarText?: string;
  /** @deprecated `hintHoldFacePositionCountdownText` is no longer in use and will be removed in a future major version release. */
  hintHoldFacePositionCountdownText?: string;
  hintConnectingText?: string;
  hintVerifyingText?: string;
  hintIlluminationTooBrightText?: string;
  hintIlluminationTooDarkText?: string;
  hintIlluminationNormalText?: string;
  hintHoldFaceForFreshnessText?: string;
};

export type CameraDisplayText = {
  cameraMinSpecificationsHeadingText?: string;
  cameraMinSpecificationsMessageText?: string;
  cameraNotFoundHeadingText?: string;
  cameraNotFoundMessageText?: string;
  retryCameraPermissionsText?: string;
};

export type InstructionDisplayText = {
  instructionsHeaderHeadingText?: string;
  instructionsHeaderBodyText?: string;
  instructionsBeginCheckText?: string;
  photosensitivyWarningHeadingText?: string;
  photosensitivyWarningBodyText?: string;
  photosensitivyWarningInfoText?: string;
  instructionListHeadingText?: string;
  goodFitCaptionText?: string;
  goodFitAltText?: string;
  tooFarCaptionText?: string;
  tooFarAltText?: string;
  instructionListStepOneText?: string;
  instructionListStepTwoText?: string;
  instructionListStepThreeText?: string;
  instructionListStepFourText?: string;
};

export type StreamDisplayText = {
  recordingIndicatorText?: string;
  cancelLivenessCheckText?: string;
};

export const defaultErrorDisplayText = {
  timeoutMessageText:
    "Il volto non è riuscito a entrare nell'ovale entro il limite di tempo. Riprova e riempi completamente l'ovale con il volto al suo interno.",
  faceDistanceHeaderText: "Rilevato movimento in avanti",
  faceDistanceMessageText: "Evita di avvicinarti durante la connessione.",
  multipleFacesHeaderText: "Rilevati volti multipli",
  multipleFacesMessageText:
    "Assicurati che sia presente solo un volto di fronte alla telecamera durante la connessione.",
  clientHeaderText: "Errore del cliente",
  clientMessageText: "Controllo non riuscito a causa di un problema del cliente",
  serverHeaderText: "Problema del server",
  serverMessageText: "Impossibile completare il controllo a causa di un problema del server",
  landscapeHeaderText: "Orientamento in orizzontale non supportato",
  landscapeMessageText:
    "Ruota il tuo dispositivo in orientamento verticale (portrait).",
  portraitMessageText:
    "Assicurati che il tuo dispositivo rimanga in orientamento verticale (portrait) per tutta la durata del controllo.",
  tryAgainText: "Riprova"
};

export type ErrorDisplayTextFoo = typeof defaultErrorDisplayText;
export type ErrorDisplayText = Partial<ErrorDisplayTextFoo>;

export const defaultLivenessDisplayText: Required<LivenessDisplayText> = {
  instructionsHeaderHeadingText: "Controllo di autenticità",
  instructionsHeaderBodyText:
    "Effettuerai un processo di verifica del volto per dimostrare che sei una persona reale.",
  instructionsBeginCheckText: "Inizia il controllo",
  photosensitivyWarningHeadingText: "Avviso di fotosensibilità",
  photosensitivyWarningBodyText:
    "Questo controllo mostra luci colorate. Usa cautela se sei fotosensibile.",
  photosensitivyWarningInfoText:
    "Una piccola percentuale di individui può sperimentare crisi epilettiche quando esposti a luci colorate. Usa cautela se tu o qualcuno della tua famiglia ha una condizione epilettica.",
  instructionListHeadingText: "Segui le istruzioni per completare il controllo:",
  goodFitCaptionText: "Inquadratura giusta",
  goodFitAltText:
    "Illustrazione del volto di una persona che si adatta perfettamente all'interno di un ovale.",
  tooFarCaptionText: "Troppo lontano",
  tooFarAltText:
    "Illustrazione del volto di una persona all'interno di un ovale; c'è uno spazio tra il perimetro del volto e i confini dell'ovale.",
  instructionListStepOneText:
    "Quando appare un ovale, segui le istruzioni per adattare il tuo volto al suo interno.",
  instructionListStepTwoText: "Massimizza la luminosità dello schermo.",
  instructionListStepThreeText:
    "Assicurati che il tuo volto non sia coperto da occhiali da sole o una maschera.",
  instructionListStepFourText:
    "Spostati in un luogo ben illuminato che non sia alla luce diretta del sole.",
  cameraMinSpecificationsHeadingText:
    "La fotocamera non soddisfa le specifiche minime",
  cameraMinSpecificationsMessageText:
    "La fotocamera deve supportare una risoluzione di almeno 320*240 pixel e 15 frame al secondo.",
  cameraNotFoundHeadingText: "Fotocamera non accessibile.",
  cameraNotFoundMessageText:
    "Verifica che la fotocamera sia collegata e che le autorizzazioni per la fotocamera siano abilitate nelle impostazioni prima di riprovare.",
  retryCameraPermissionsText: "Riprova",
  cancelLivenessCheckText: "Annulla il controllo di autenticità",
  recordingIndicatorText: "Registrazione",
  hintMoveFaceFrontOfCameraText: "Sposta il volto davanti alla fotocamera",
  hintTooManyFacesText: "Assicurati che ci sia solo un volto davanti alla fotocamera",
  hintFaceDetectedText: "Volto rilevato",
  hintCanNotIdentifyText: "Sposta il volto davanti alla fotocamera",
  hintTooCloseText: "Spostati indietro",
  hintTooFarText: "Avvicinati",
  hintHoldFacePositionCountdownText: "Tieni ferma la posizione del volto durante il conto alla rovescia",
  hintConnectingText: "Connessione...",
  hintVerifyingText: "Verifica...",
  hintIlluminationTooBrightText: "Sposta in una zona meno illuminata",
  hintIlluminationTooDarkText: "Spostati in una zona più illuminata",
  hintIlluminationNormalText: "Condizioni di illuminazione normali",
  hintHoldFaceForFreshnessText: "Rimani fermo",
  ...defaultErrorDisplayText,
};

export interface LivenessDisplayText
  extends HintDisplayText,
    CameraDisplayText,
    InstructionDisplayText,
    ErrorDisplayText,
    StreamDisplayText {}



